<template>
  <div class="agreement-document d-flex-column py-4 px-lg-16 px-md-12 px-8">
    <div class="agreement-document__wrapper d-flex flex-wrap">
      <div class="agreement-document__area">
        <v-row class="pt-8" v-if="loading" align="center" justify="center">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            />
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="title">{{ $t('loading') }}</span>
          </v-col>
        </v-row>
        <v-row v-for="(htmlDocument, index) in htmlDocuments" :key="index">
          <v-col class="py-4">
            <div class="d-flex flex-wrap pb-4 text-body-2" v-html="htmlDocument.html"></div>
            <v-divider
              v-if="index !== htmlDocuments.length - 1"
              class="agreement-document__divider d-flex mt-4"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="agreement-document__footer d-flex flex-wrap" v-if="!loading">
      <v-row class="text-left mt-2 mx-auto" style="flex: 0 0 100%">
        <v-col cols="12" class="py-1 py-sm-3 px-1 px-sm-3">
          <v-checkbox
            class="mt-0"
            v-model="hasRead"
            hide-details
            :label="$t('agreementDocuments.readDisclaimer')"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="px-1 px-sm-3" :class="{'text-right': !isMobile, 'd-flex justify-space-between': isMobile}">
          <v-btn
            small
            depressed
            rounded
            class="mr-4"
            @click="denyAgreementDocument"
            style="min-width: auto"
            >{{ $t('agreementDocuments.deny') }}
          </v-btn>
          <v-btn
            small
            depressed
            rounded
            color="primary"
            :loading="loadingAccept"
            @click="acceptAgreementDocument"
            :disabled="!hasRead"
            style="min-width: auto"
            >{{ $t('agreementDocuments.accept') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import agreementService from '@/services/agreement.service';

const agreementsModule = createNamespacedHelpers('agreements')
const authModule = createNamespacedHelpers('auth');

export default {
  name: 'AgreementDocumentView',
  data: () => ({
    loadingAccept: false,
    hasRead: false
  }),
  methods: {
    ...authModule.mapActions(['logout']),
    ...agreementsModule.mapActions(['setPendingHtmlDocuments', 'acceptLegalAgreementDocuments', 'setError']),
    async acceptAgreementDocument() {
      this.loadingAccept = true;
      await this.acceptLegalAgreementDocuments(this.beneficiaryAttributes)
      this.loadingAccept = false;
    },
    async loadAgreementDocument() {
      try {
        const promises = this.pendingAgreementDocuments.map((pendingDocument) =>
          agreementService.downloadDocument(pendingDocument)
        );
        const documents = await Promise.all(promises);

        const htmlDocuments = documents.map((document) => ({
          html: document.data
        }));
        this.setPendingHtmlDocuments(htmlDocuments)
      } catch (error) {
        this.setError({ type: 'error', message: 'globalErrorUnknown'})
      }
    },
    denyAgreementDocument() {
      this.logout();
      this.setError({ type: 'error', message: 'agreementDocuments.denied'})
    },
  },
  computed: {
    ...agreementsModule.mapGetters(['pendingAgreementDocuments', 'pendingHtmlDocuments']),
    ...authModule.mapGetters(['beneficiaryAttributes']),
    htmlDocuments() {
      return this.pendingHtmlDocuments
    },
    loading() {
      return this.htmlDocuments.length === 0
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  mounted() {
    this.loadAgreementDocument();
  }
}
</script>

<style lang="scss" scoped>
.agreement-document {
  flex: 1;
  min-height: 400px;
  overflow: visible;
  &__wrapper {
    flex: 1;
    align-content: center;
    justify-content: center;
  }
  &__area {
    flex: 0 0 100%;
    min-height: 200px;
  }
  &__icon,
  &__message,
  &__btn,
  &__divider {
    flex: 0 0 100%;
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: sticky;
    bottom: 50px;
    background: white;
    border-top: 1px #e0e0e0 solid;
  }
}
</style>